import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SimpleLoader from "./shared/SimpleLoader";
import RedirectIfNotAuthenticated from "./shared/RedirectIfNotAuthenticated";

const Analytics = lazy(() => import("./features/analytics/Analytics"));
const BookList = lazy(() => import("./features/book/BookList"));
const BookDetails = lazy(() => import("./features/book/BookDetails"));
const GenreList = lazy(() => import("./features/genre/GenreList"));
const GenericDataDetails = lazy(() => import("./shared/GenericDataDetails"));
const Home = lazy(() => import("./features/home/Home"));
const Login = lazy(() => import("./features/login/Login"));
const PersonList = lazy(() => import("./features/person/PersonList"));
const PersonDetails = lazy(() => import("./features/person/PersonDetails"));
const RedeemToken = lazy(() => import("./features/login/RedeemToken"));
const Search = lazy(() => import("./features/search/Search"));
const Stats = lazy(() => import("./features/stats/Stats"));
const TagList = lazy(() => import("./features/tag/TagList"));

const AppRoutes = () => {
    return (
        <Suspense fallback={<SimpleLoader />}>
            <Switch>
                {/* public routes */}
                <Route path="/home" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/redeem-token" component={RedeemToken} />
                {/* authenticated routes */}
                <Route path="/analytics" render={() => <RedirectIfNotAuthenticated children={<Analytics />} />} />} />
                <Route path="/book" exact render={() => <RedirectIfNotAuthenticated children={<BookList />} />} />} />
                <Route path="/book/:id" render={(props) => <RedirectIfNotAuthenticated children={<BookDetails {...props} />} />} />} />
                <Route path="/genre" exact render={() => <RedirectIfNotAuthenticated children={<GenreList />} />} />} />
                <Route
                    path="/genre/:id"
                    render={(props) => (
                        <RedirectIfNotAuthenticated children={<GenericDataDetails table="Genre" includeDescription {...props} />} />
                    )}
                />
                } />
                <Route path="/person" exact render={() => <RedirectIfNotAuthenticated children={<PersonList />} />} />} />
                <Route path="/person/:id" render={(props) => <RedirectIfNotAuthenticated children={<PersonDetails {...props} />} />} />} />
                <Route path="/search" render={() => <RedirectIfNotAuthenticated children={<Search />} />} />} />
                <Route path="/stats" render={() => <RedirectIfNotAuthenticated children={<Stats />} />} />} />
                <Route path="/tag" exact render={() => <RedirectIfNotAuthenticated children={<TagList />} />} />} />
                <Route
                    path="/tag/:id"
                    render={(props) => (
                        <RedirectIfNotAuthenticated children={<GenericDataDetails table="Attribute" includeDescription {...props} />} />
                    )}
                />
                } />
                <Route
                    path="/character-element/:id"
                    render={(props) => (
                        <RedirectIfNotAuthenticated children={<GenericDataDetails table="CharacterElementType" {...props} />} />
                    )}
                />
                } />
                <Route
                    path="/picture-medium/:id"
                    render={(props) => (
                        <RedirectIfNotAuthenticated children={<GenericDataDetails table="PictureMediumType" {...props} />} />
                    )}
                />
                } />
                <Redirect from="/" to="/home" />
            </Switch>
        </Suspense>
    );
};

export default AppRoutes;
