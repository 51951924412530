import React, { useContext, ReactNode } from "react";
import AuthContext from "./AuthContext";
import { Redirect } from "react-router-dom";

interface RedirectProps {
    children: ReactNode;
}

const RedirectIfNotAuthenticated: React.FC<RedirectProps> = (props: RedirectProps) => {
    const { children } = props;
    const { user } = useContext(AuthContext);
    const currentTime = Math.floor(Date.now() / 1000);
    if (user && currentTime < user.exp) {
        return <>{children}</>;
    }

    return <Redirect to="/login" />;
};

export default RedirectIfNotAuthenticated;
