import React from "react";
import { Header, Icon } from "semantic-ui-react";

interface SimpleLoaderProps {
    message?: string;
}

const SimpleLoader: React.FC<SimpleLoaderProps> = (props: SimpleLoaderProps) => {
    const { message } = props;
    return <Header color="grey" icon={<Icon name="cog" loading />} content={message || "Loading..."} textAlign="center" />;
};

export default SimpleLoader;
