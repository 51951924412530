import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import { Grid } from "semantic-ui-react";
import { User } from "./types";
import { clearToken, getToken } from "./services/StorageProvider";
import decode from "jwt-decode";
import AuthContext from "./shared/AuthContext";

const App: React.FC = () => {
    const [user, setUser] = useState<User>();
    const logout = () => {
        clearToken();
        setUser(undefined);
    };

    if (!user) {
        const token = getToken();
        if (token) {
            setUser(decode(token));
        }
    }

    return (
        <Router>
            <AuthContext.Provider
                value={{
                    user,
                    setUser,
                    logout
                }}
            >
                <Navbar />
                <Grid padded="horizontally">
                    <Grid.Row>
                        <Grid.Column>
                            <AppRoutes />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </AuthContext.Provider>
        </Router>
    );
};

export default App;
