import React, { useState, FormEvent, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Menu, Image, Form, Input, Dropdown, Button } from "semantic-ui-react";
import logo from "../img/book-only-32x32.png";
import AuthContext from "./AuthContext";

const Navbar = () => {
    const [search, setSearch] = useState<string>("");
    const [redirect, setRedirect] = useState<string>();

    const { user, logout } = useContext(AuthContext);

    const history = useHistory();

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!search) {
            return;
        }
        setRedirect(search);
    };

    if (redirect) {
        setSearch("");
        setRedirect(undefined);
        const url = `/search?query=${encodeURIComponent(redirect)}`;
        history.push(url);
    }

    return (
        <Menu tabular={false}>
            <Menu.Menu position="left">
                <Menu.Item content={<Image src={logo} />} as={Link} to="/home" />

                {!!user && (
                    <>
                        <Menu.Item name="Books" icon="book" as={Link} to="/book" />
                        <Menu.Item name="People" icon="users" as={Link} to="/person" />
                        <Menu.Item name="Genres" icon="tag" as={Link} to="/genre" />
                        <Menu.Item name="Tags" icon="tag" as={Link} to="/tag" />
                    </>
                )}
            </Menu.Menu>

            {!user && (
                <Menu.Menu position="right">
                    <Menu.Item>
                        <Button basic color="grey" as={Link} to="/login" content="Sign in" size="tiny" />
                    </Menu.Item>
                </Menu.Menu>
            )}

            {!!user && (
                <Menu.Menu position="right">
                    <Menu.Item>
                        <Form onSubmit={onSubmit}>
                            <Input
                                placeholder="Quick Search"
                                minLength="2"
                                value={search}
                                onChange={(e, { value }) => setSearch(value)}
                                iconPosition="left"
                                icon="search"
                            />
                        </Form>
                    </Menu.Item>
                    <Dropdown item text={`Welcome ${user.preferredName}`}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to="/login" onClick={logout} icon="log out" content="Log out" />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            )}
        </Menu>
    );
};

export default Navbar;
