import { createContext } from "react";
import { User } from "../types";

export interface AuthContextProps {
    user?: User;
    setUser: (user: User | undefined) => void;
    logout: () => void;
}

export default createContext<AuthContextProps>({
    setUser: () => {
        throw new Error("not implemented");
    },
    logout: () => {
        throw new Error("not implemented");
    }
});
